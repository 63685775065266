<template>
  <div id="content" ref="content" class="404">
    <b-container id="step-1" style="background:#000028;color:white;">
      <b-row class="mt-5 pt-5 mb-5 pb-5">

        <b-col>
          <div class="headline">
            <h5>Seite konnte nicht gefunden werden </h5>
            <h3>Die angeforderte Seite konnte nicht gefunden werden.</h3>
          </div>
          <p>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'error',
  metaInfo: {
    title: '404 - My Rail Services',
    titleTemplate: '%s | Siemens',
    meta: [
      {
        name: 'dc.type',
        content: '404'
      },
      {
        name: 'robots',
        content: 'noindex'
      }
    ]
  },
  props: {activeStep: Number},
  data: function () {
    return {
      lastHoveredServiceCategory: 0,
      hoveredServiceCategory: 0,
      serviceSelectionStepPre: 1,
      serviceSelectionStepCor: 1,
    }
  },
  methods: {
    scrollFix: function (hashbang) {
      location.hash = hashbang;
    },
    chooseDate() {
      this.$router.push('/choose-date')
    },
    enterServiceCategory(serviceCategory) {
      if (this.lastHoveredServiceCategory !== serviceCategory) {
        // this.resetSelectedServices()
      }
      this.hoveredServiceCategory = serviceCategory
    },
    leaveServiceCategory(serviceCategory) {
      this.lastHoveredServiceCategory = serviceCategory
      this.hoveredServiceCategory = 0
    },
    scrollToStep2() {
      const href = document.getElementById("step-2");
      this.$refs.content.scrollTop = href.offsetTop
    },
    scrollIntoView(event) {
      event.preventDefault()
      const href = event.target.getAttribute('href')
      const el = href ? document.querySelector(href) : null
      if (el) {
        this.$refs.content.scrollTop = el.offsetTop
      }
    }
  },
  mounted() {
    setTimeout(() => this.scrollFix(this.$route.hash), 1);
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'availableTrainCategories',
      'selectedTrainCategory',
      'availableServiceCategories',
      'selectedServices',
      'servicesByCategory',
      'serviceAvailable',
      'canBeAddedToCart',
      'additionalServiceInformation',
      'orderItems'
    ]),
  }
}
</script>
<style lang="scss">
.hero-wrapper {
  min-height: 500px;
  background-image: url("../assets/header.jpg");
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  background-position: bottom center;

  &:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
  }
}

.hero-content {
  margin-top: 150px;
  @media(min-width: 767px) {
    margin-top: 40px;
  }

  .hero-headline {
    position: relative;
    padding-left: 30px;
    margin-bottom: 30px;

    &:before {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background-image: linear-gradient(179deg, #00FFB9 0%, #00E6DC 100%);
    }
  }

  color: white;
}

.hero {
  min-height: 500px;
  position: relative;
}


.headline {
  position: relative;
  padding-left: 25px;
  margin-bottom: 30px;

  &:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-image: linear-gradient(179deg, #00FFB9 0%, #00E6DC 100%);
  }
}

.category-btn {
  margin-left: 0.5rem;

  @media screen and (min-width: 600px) {
    &:first-of-type {
      margin-left: 0;
    }
  }
}

.navigation-dots {
  position: fixed;
  right: 5px;
  top: 50%;
  height: 50px;
  margin-top: -25px;
  line-height: 0;

  .navigation-dot {
    background: rgba(255, 255, 255, 0.35);
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50px;

    &:hover {
      background: rgba(255, 255, 255, 1);
    }
  }

  .active {
    .navigation-dot {
      background: rgba(255, 255, 255, 1);
    }
  }
}

.card-text.card-margin {
  margin-top: -25px;
}
</style>
